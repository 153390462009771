<template>
  <!-- <v-btn x-small :elevation="0" :color="color" class="status">
    {{ statusLabel }}
  </v-btn> -->
  <v-chip class="status text-center" :color="color" text-color="white">
    {{ statusLabel }}
    <div v-if="loading" class="ml-4">
      <v-progress-circular
        indeterminate
        color="primary"
        size="24"
      ></v-progress-circular>
    </div>
  </v-chip>
</template>
<script>
/**
 * ==================================================================================
 * Status
 * ==================================================================================
 **/

import { capitalize } from '@/utils/helpers'

/**
 * Pick `color` name on `vuetify` color page
 * https://vuetifyjs.com/en/styles/colors/#material-colors
 */
const STATUS = {
  blue: ['pending', 'active', 'new'],
  '#f2935c': ['in progress'],
  '#00c853': ['approved', 'accepted'],
  '#a4a9b1': ['done', 'finished', 'completed', 'unread'],
  red: ['declined', 'cancelled', 'canceled'],
  black: ['deleted', 'blocked'],
  '#297fd3': ['read', 'delivered'],
  '#e15241': ['undelivered'],
}

export default {
  name: 'Status',
  props: {
    status: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    parsedStatus() {
      return this.status ? this.status.replace(/_/g, ' ') : ''
    },

    statusLabel() {
      return capitalize(this.parsedStatus)
    },

    color() {
      return this.getColor(this.status)
    },
  },

  methods: {
    getColor() {
      if (!this.parsedStatus) return null

      for (var key in STATUS) {
        if (STATUS[key].includes(this.parsedStatus.toLowerCase())) {
          return key
        }
      }

      return null
    },
  },
}
</script>
<style lang="scss" scoped>
.status {
  font-weight: bold;
  text-transform: none !important;
  color: white;
}
</style>
